import React from 'react';

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import Layout from '../../components/Layout';
import Card from '../../components/Card';

import lukeEvans from '../../assets/images/luke.jpeg';

import twitter from '../../assets/images/bx_bxl-twitter.svg';
import facebook from '../../assets/images/bx_bxl-facebook.svg';
import linkedin from '../../assets/images/bx_bxl-linkedin.svg';
import helgaButcher from '../../assets/images/helga-butcher-article.jpg';
import buildings from '../../assets/images/buildings.jpg';
import fibShot from '../../assets/images/fib-shot-3.jpg';
import fibonacci from '../../assets/images/fibonacci-full.svg';


export default ({ path }) => (
  <Layout
    pageName="alternative-article"
    path={path}
    metatitle="From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays"
    image="/metaimages/homepage.jpg"
    description="Whilst navigating the unprecedented effects that Covid-19 has had on businesses globally, scrutiny over expenditure has never been more rigorous..."
  >
    <div className="page">
      <div className="banner -resources">
        <div className="chip">NEWS</div>
        <div className="heading">
          <img className="image" src={helgaButcher} alt="" />
          <h1>
            From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays
          </h1>
        </div>
      </div>
      <div className="article-container">
        <div className="content">
          <p className="paragraph">
            Whilst navigating the unprecedented effects that Covid-19 has had on businesses
            globally, scrutiny over expenditure has never been more rigorous. Consequently, we are
            seeing increased pressure on in-house teams to be more efficient and cost-effective,
            particularly when engaging external counsel - a notoriously high-cost exercise.
          </p>
          <p className="paragraph">
            We sat down with
            {' '}
            <b>Helga Butcher</b>
            , legal project portfolio manager at
            &nbsp;
            <b>Barclays</b>
            , to discuss how legal project management (LPM) principles, coupled
            with the right technology solutions, can supercharge both corporates and their legal
            service providers in the efficient delivery of legal services.
          </p>
          <p className="paragraph">
            <b>
              As it currently stands, how does Barclays deliver LPM expertise, and what are the
              opportunities to leverage technology solutions?
            </b>
          </p>
          <p className="paragraph">
            At the moment, service delivery is done on a relatively manual basis. Our legal project
            managers help to deliver on the larger, particularly complex matters where their
            coordination and planning expertise are needed the most.
          </p>
          <p className="paragraph">
            What has hampered our efforts both on the Barclays side and on the law firm side, is
            that there hasn’t been a single platform on which to collaborate across the board and
            therefore give us synergy and consistency when we interact with our panel firms.
          </p>
          <p className="paragraph">
            In order to drive efficiencies in how Barclays manages its legal services, we’ve been
            on the lookout for a technology partner for a while now. That means one that can help
            us to better manage our relationships in the broadest sense with our panel firms,
            from managing high-value high-risk matters to tendering, as well as managing the
            supplier obligations that we must fulfill in the process.
          </p>
          <p className="paragraph">
            <b>
              Do you feel that innovation in that technology space is also about opening the
              communication channels up?
            </b>
          </p>
          <p className="paragraph">
            Absolutely: in the same way that our matters are still managed in a relatively manual
            fashion, we have well-defined, well-developed, and well-adhered to processes for
            managing the relationships with our panel firms.
          </p>
          <p className="paragraph">
            Barclays is now looking to an active panel-management process, rather than the complex
            and often labor-intensive RFP process. In order to enable that active panel-management
            process, technology is key to drive efficiency. So yes, we will absolutely be looking
            towards technology to support those processes.
          </p>
          <p className="paragraph">
            The efficiencies that are gained benefit both sides: for Barclays, it reduces the
            administrative effort of maintaining the relationship and the associated reporting.
            Similarly, for the law firms, it reduces the burden and resource requirement that goes
            towards those same responsibilities.  Therefore, if law firms adopted a single
            collaboration platform, they could then push tailored and relevant content to not just
            one of their clients, but to many.
          </p>
          <p className="paragraph">
            <b>
              Do you feel that the industry is progressive in embracing new technologies, or do
              firms risk being left behind if they do not adapt - in particular those firms that
              want to work with you going forward?
            </b>
          </p>
          <p className="paragraph">
            Yes, I think that when a corporate discovers the tech solution that enables them to work
            more efficiently, then an inevitable consequence of that is that your vendors need to
            move with you as well.
          </p>
          <p className="paragraph">
            In that sense, we are no different from an HR or IT department, or whatever function
            within the bank that you are looking at. But, specific to the legal department, the
            development of technology has taken a long time to get to this space, particularly a
            solution that actually fits our needs and our requirements.
          </p>
          <p className="paragraph">
            Now that we have identified this solution in Fibonacci, those law firms that are slow to
            adopt innovative solutions and actually invest the effort to get the technology running
            smoothly and efficiently will likely struggle to keep up in terms of managing their
            relationships and other obligations efficiently.
          </p>
          <p className="paragraph">
            <b>
              Barclays has a large number of firms on their panel and a complex RFP process: do you
              feel that there is a disconnect between the scope agreed upon at the outset of a
              project, when the work is awarded, compared to what is delivered?
            </b>
          </p>

          <p className="paragraph">
            It’s an open secret that we often have that frustration, between promises made in tender
            and the eventual output.This is not to say that each and every project is a challenge,
            but at times they can be.
          </p>
          <p className="paragraph">
            What I would say is that our systems don’t allow us to easily connect the dots between
            the relationship period, the tendering process and the delivery and conclusion process
            of a matter. Having access to a technology platform that promotes collaboration will
            help us connect those dots.This will be very powerful, because whilst we have extensive
            expertise in our external engagement team - particularly when scoping and controlling
            the scope of a matter, what we are lacking is actionable data: data that is reliable,
            easily identifiable and therefore usable.
          </p>
          <p className="paragraph">
            By having a technology solution that actually stores and easily links that data to new
            engagements will be a very powerful support tool indeed.
          </p>
          <p className="paragraph">
            <b>
              Where is the opportunity for technology to add the most value whilst navigating the
              relationship between the Barclays in-house legal team and their engaged legal service
              providers?
            </b>
          </p>
          <p className="paragraph">
            One of the cornerstones of our vendor and law firm relationship effort is that the
            firms that work with us effectively become an extension of our in-house team. This
            promotes a seamless way of working, whether that be in M&A, litigation, regulatory or
            any other part of the legal function. Subsequently, we need to have clarity as to what
            is happening on our matters at all times: reliable, actionable real-time insights are
            absolutely essential.
          </p>
          <p className="paragraph">
            Additionally, our legal team and engaged legal providers act as advisors to the
            business, and so having the ability to engage key stakeholders both within the business
            and externally, all in one platform, is absolutely fundamental, as well as being able to
            tailor and make relevant that experience.
          </p>
          <p className="paragraph">
            You could argue that law firms have the ability to open up their practice management
            systems in a similar fashion, but the challenge for the client is that each firm has a
            slightly different system and subsequent application of their system. This is where the
            utility and uniformity of a single comprehensive platform shines, particularly in terms
            of reporting and access to data.
          </p>
          <p className="paragraph">
            <b>
              Will having that enhanced visibility across task completion, financial performance and
              progress updates lead to a more effective tenure between Barclays and its panel firms?
            </b>
          </p>
          <p className="paragraph">
            Yes, we actively practice this: my team of legal project managers will regularly give
            feedback to the relationship team regarding service delivery whilst planning high-value
            matters. However, having access to the additional data points that the Fibonacci
            platform presents will supercharge that feedback loop, and will also help us effectively
            make better, more informed decisions when it comes to new mandates.
          </p>
          <p className="paragraph">
            When new work is being tendered, there is a manual control element when deciding which
            firms from our community are to be on the shortlist, and being able to support that
            decision with intelligent, real-time data insights gained from Fibonacci will be a huge
            benefit.
          </p>
          <p className="paragraph">
            Moreover, a big part of our role as legal project managers is about coaching and
            mentoring our law firms, particularly in the pricing and in the commercial arm of our
            external engagement team. It’s commonplace that we present to teams of partners at a
            matter level, as well as more broadly at a practice level, in order to align on our
            commercial objectives.
          </p>
          <p className="paragraph">
            We can strip-out some of the blockers that arise in this process if we don’t have to
            rely solely on complex Excel spreadsheets, which can easily throw users off. Using a
            platform like Fibonacci, it’s not all cells and formulas, and is refreshingly intuitive
            so that a lawyer can easily pick it up and work with it from the get-go.
          </p>
          <p className="paragraph">
            <b>
              What are the top 5 trends that you see on the horizon for LPM?
            </b>
          </p>
          <p className="paragraph">
            LPM is here to stay. The impacts of the current crisis and the renewed focus on budget
            is here to stay.
          </p>
          <p className="paragraph">
            There’s definitely a positive equation that you create when you involve a legal project
            manager on a complex matter, in that it protects law firm profitability whilst
            streamlining the management of in-house legal budgets. Ultimately, both the client and
            the law firm vendor benefit from reduced costs, efficiency and transparency, and you can
            do that by running the service delivery more efficiently.
          </p>
          <p className="paragraph">
            That desire and focus on collaboration will continue to be there - that will never go
            away. We will never go back to a time in which clients are happy to pay excessive fees:
            no corporate can afford to do that. Corporates are under huge scrutiny, particularly
            regulated businesses, as many businesses are these days, and therefore can’t be seen
            to be wasting valuable budget on excessive fees.
          </p>
          <p className="paragraph">
            There is a distinct continued drive for transparency: clients want to know what law
            firms are doing for their money - LPMs help to shed a light on that and, crucially,
            LPM technology helps them do so. It does not mean that there is an added burden of
            responsibility for lawyers on the law firm side, but rather that they can better focus
            their efforts on winning new work, or working on different projects, rather than
            becoming weighed down in manual and administrative activities.
          </p>
          <p className="paragraph">
            The continued drive for technology to support this trend is absolutely essential,
            compounded by the fact that more of us are going to work in an increasingly digital
            fashion, from de-centralised hubs and individual homes. Having the right legal service
            delivery technology to encourage and underpin that collaboration piece between
            corporates and the law firms that service them is absolutely key.
          </p>
          <p className="paragraph">
            Another thing that any technology implementation flushes out are the questions around
            how we do things currently, and how we want to do things better in the future. Legal
            process improvements - chipping away at inefficiencies, removing barriers, understanding
            what controls there are and why they’re there, and maybe challenging some of those, will
            continue to be a key focus going forwards.
          </p>
          <p className="paragraph">
            <b>
              What is the one thing that you could change in the legal sector, right now, if given
              the chance?
            </b>
          </p>
          <p className="paragraph">
            Kill email!
          </p>
          <p className="paragraph">
            <i>Interested to see how Fibonacci can transform your legal operations?</i>
          </p>
          <div className="author-details">
            <div className="label _bold">Author</div>
            <div className="base-chip -large author">
              <img className="avatar -no-border" src={lukeEvans} alt="" />
              <div className="text">
                <div className="title">
                  <span className="name">Luke Edwardes-Evans</span>
                </div>
                <div className="subtitle">Product Success Manager, Fibonacci</div>
                <img className="logo -custom" src={fibonacci} alt="" />
              </div>
            </div>
            <a
              className="base-button -inline -message message-link"
              href="mailto:luke@fibonacci.legal"
            >
              Message Luke
            </a>
          </div>
        </div>
        <div className="social-container social">
          <div className="header">Like this article? Spread the word</div>
          <div className="logos">
            <LinkedinShareButton
              title="From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays"
              url="https://fibonacci.legal/blog/from-the-legal-project-managers-mouth"
            >
              <div className="social-button">
                <img src={linkedin} alt="" />
              </div>
            </LinkedinShareButton>
            <TwitterShareButton
              title="From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays"
              url="https://fibonacci.legal/blog/from-the-legal-project-managers-mouth"
            >
              <div className="social-button">
                <img src={twitter} alt="" />
              </div>
            </TwitterShareButton>
            <FacebookShareButton
              quote="From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays"
              url="https://fibonacci.legal/blog/from-the-legal-project-managers-mouth"
            >
              <div className="social-button">
                <img src={facebook} alt="" />
              </div>
            </FacebookShareButton>
          </div>
        </div>
        <h2 className="_bold divider">Keep reading</h2>
        <div className="card-gallery gallery">
          <Card
            className="heading"
            to="/blog/transforming-multi-jurisdictional-litigation-with-bird-and-bird"
            image={buildings}
            title="Transforming multi-jurisdictional litigation with Bird & Bird"
            author="Raphael Cardona"
          />
          <Card
            image={fibShot}
            title="Fibonacci: the Golden Standard for Legal Project Management"
            to="/blog/fibonacci-the-golden-standard-for-legal-project-management"
            author="Luke Edwardes-Evans"
            imageStyle={{ transform: 'scale(1.4)' }}
          />
        </div>
      </div>
      <div className="topbg" />
      <div className="bottombg" />
    </div>
  </Layout>
);
